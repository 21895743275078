export const model = {
    id: "",
    image: "",
    full_logo: "",
    address: "",
    owner_branch_id: "",
    search: "",
    created_at: "",
    updated_at: "",
    start_date: '',
    end_date: '',
};