import { sort } from "../properties/sort";
import { filter } from "./properties/filter";
import { pagination } from "../properties/pagination";
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';

export const mutations = {
    SET_LIST: (state, users) => (state.list = users),
    SET_FREE_LIST: (state, users) => (state.free_list = users),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    SET_INVENTORY: (state, inventory) => (state.inventory = inventory),
    SET_INVENTORY_GENERAL: (state, inventory_general) => (state.inventory_general = inventory_general),
    SET_DOCTORLIST: (state, doctor_list) => (state.doctor_list = doctor_list),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, user) => {
        /***
         * set user main data
         */

        state.model = JSON.parse(JSON.stringify(user));
        /***
         * set user permissions
         */
        if (user.role) {
            if (user.role.permissions) {
                state.permissions = user.role.permissions
            }
        }

        /***
         * set user services ids
         */
        // let services_id = [];
        // if (user.doctor_services){
        //     for (const key in user.doctor_services) {
        //         if (user.doctor_services.hasOwnProperty(key)) {
        //             const element = user.doctor_services[key];
        //             services_id.push(element.service_id)
        //         }
        //     }
        // }
        // state.model.doctor_services = services_id

        /***
         * set user specialties id
         */
        let specialties_id = [];
        if (user.specialties) {
            for (const key in user.specialties) {
                if (user.specialties.hasOwnProperty(key)) {
                    const element = user.specialties[key];
                    specialties_id.push(element.specialty_id)
                }
            }
        }
        state.model.specialties = specialties_id


        /***
         * set user employee_groups
         */
        state.model.employee_groups = [];
        if (user.employee_groups) {
            if (user.employee_groups) {
                for (let key in user.employee_groups) {
                    if (user.employee_groups.hasOwnProperty(key)) {
                        state.model.employee_groups.push(user.employee_groups[key].id)
                    }
                }
            }
        }
        /***
         * TODO: //get user audits.
         */
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));
        state.permissions = [];
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    UPDATE_LANG_COLUMN: (state) => {
        state.columns.id.title = '№';
        state.columns.name.title = i18n.t('message.first_name');
        state.columns.surname.title = i18n.t('message.surname');
        state.columns.patronymic.title = i18n.t('message.patronymic');
        state.columns.email.title = i18n.t('message.email');
        state.columns.phone.title = i18n.t('message.phone');
        state.columns.password.title = i18n.t('message.password');
        state.columns.phone_two.title = i18n.t('message.phone_two');
        state.columns.role_id.title = i18n.t('message.role');
        state.columns.role_name.title = i18n.t('message.role_name');
        state.columns.doctor_services.title = i18n.t('message.doctor_service');
        state.columns.gender.title = i18n.t('message.gender');
        state.columns.id_code.title = i18n.t('message.id_code');
        state.columns.locked.title = i18n.t('message.locked');
        state.columns.last_login.title = i18n.t('message.last_login');
        state.columns.specialties.title = i18n.t('message.specialities');
        state.columns.created_at.title = i18n.t('message.date');
        state.columns.updated_at.title = i18n.t('message.update_date');
        state.columns.settings.title = i18n.t('message.settings');
        state.columns.room_id.title = i18n.t('message.rooms_number');
    }

};
