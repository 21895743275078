import {
    index,
    orderSericeTerm,
    show,
    back,
    relation_show,
    service_show,
    store,
    update,
    destroy,
    inventory,
    updateOrder,
    updateStatus,
    print,
    chart,
    patientList,
    miniPrint,
    patientMedicine,
    patientMedicineShow,
    massOrder,
    expiredService,
    chartOrderCount,
    deleteService
} from '../../../api/orders';
import {
    excelReport,
    excelReport2,
    excelReport3
} from '../../../api/excelReport';
export const actions = {

    index({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit("SET_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", {
                    key: 'total',
                    value: res.data.data.orders.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    inventory({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params)
                .then((res) => {
                    commit("SET_INVENTORY", res.data.data.orders.data);
                    commit("UPDATE_PAGINATION", {
                        key: 'total',
                        value: res.data.data.orders.pagination.total
                    });
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err.response);
                });
        });
    },

    patientList({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            patientList(params).then(res => {
                commit("SET_PATIENT_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", {
                    key: 'total',
                    value: res.data.data.orders.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientListMedicine({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            patientListMedicine(params).then(res => {
                commit("SET_PATIENT_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", {
                    key: 'total',
                    value: res.data.data.orders.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    orderSericeTerm({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            orderSericeTerm(params).then(res => {
                commit("SET_TERM_LIST", res.data.data.orders.data);
                commit("UPDATE_PAGINATION", {
                    key: 'total',
                    value: res.data.data.orders.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    excelReport({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            excelReport(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    excelReport2({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            excelReport2(params).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    excelReport3({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            excelReport3(params).then(res => {
                resolve(res.data)
            }).catch(err => {               
                reject(err.response.data)
            })
        })
    },

    show({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    expiredService({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            expiredService(params).then(res => {
                commit("SET_EXPIRED_LIST", res.data.data.order_service.data);
                commit("UPDATE_EXPIRED_PAGINATION", {
                    key: 'total',
                    value: res.data.data.order_service.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    edit({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {

                commit("SET_MODEL", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    print({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            print(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    miniPrint({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            miniPrint(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    relationShow({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            relation_show(data).then(res => {
                commit("SET_MODEL_RELATION", res.data.data.order);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    serviceShow({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            service_show(data).then(res => {
                commit("SET_MODEL_RELATION", res.data.data.order_service);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    deleteService({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            deleteService(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    back({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            back(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },


    massOrder({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            massOrder(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {

                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateOrder({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            updateOrder(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateStatus({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            updateStatus(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientMedicine({
        commit
    }, data) {
        return new Promise((resolve, reject) => {
            patientMedicine(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    patientMedicineShow({
        commit
    }, params = {}) {
        return new Promise((resolve, reject) => {
            patientMedicineShow(params).then(res => {
                commit("SET_PATIENT_MEDICINE", res.data.data.patient_medicine.data);
                commit("UPDATE_PAGINATION", {
                    key: 'total',
                    value: res.data.data.patient_medicine.pagination.total
                });
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    destroy({
        commit
    }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    updateSort({
        commit
    }, sort) {
        commit("SET_SORT", sort)
    },

    updateExpiredSort({
        commit
    }, sort) {
        commit("SET_EXPIRED_SORT", sort)
    },

    updateFilter({
        commit
    }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({
        commit
    }, obj) {
        commit('UPDATE_COLUMN', obj)
    },

    updateColumnNew({
        commit
    }, obj) {
        commit('UPDATE_COLUMN_NEW', obj)
    },

    updatePagination({
        commit
    }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },

    updateExpiredPagination({
        commit
    }, pagination) {
        commit('UPDATE_EXPIRED_PAGINATION', pagination)
    },

    refreshData({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    empty({
        commit
    }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    chart({
        commit
    }, type) {
        return new Promise((resolve, reject) => {
            chart({
                type: type
            }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    chartOrderCount({
        commit
    }, type) {
        return new Promise((resolve, reject) => {
            chartOrderCount({
                type: type
            }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

}