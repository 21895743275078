export const model = {
    id: null,
    name: '',
    code: '',
    is_service: "",
    letterhead: "",
    parent_id: "",
    price: "",
    discount_price: "",
    category_id: "",
    unnecessary_enclosure: "",
    for_outsource: "",
    enabled: true,
    created_at: '',
    updated_at: '',
    is_phy: ""
};
