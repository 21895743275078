export const filter = {
    id: "",
    patient_id: "",
    room_id: "",
    with_semi_created: true,
    day: "",
    notes: "",
    condition_id: "",
    partner_doctor_id: "",
    partner_clinic_id: null,
    user_id: null,
    yes_doctor: "",
    doctor_id: null,
    from_hour: "",
    specialties: "",
    duration: "",
    search: "",
    created_at: "",
    updated_at: "",
    with_semi_created: true,
};
